// import config from "../config";
const ApiUrlServices = {
    SIGN_IN: '/auth/authentication/signin',
    CREATE_NEW_USER:'/auth/authentication/admin-signup',
    ADMIN_FIRST_PASS_CHANGE:'/auth/authentication/admin-force-password-change',
    ADMIN_FIRST_SIGN_IN:'/auth/authentication/admin-created-first-signin',
    GET_USER_PROFILE: (userId) => `/auth/users/${userId}`,
    GET_REFRESH_TOKEN: `/auth/authentication/refresh_token`,
    GET_CATEGORIES_WITH_PAGINATION: (page, limit, parent_catid, source) => `/category/cat-list?page=${page}&limit=${limit}&parent_catid=${parent_catid}&source=${source}`,
    GET_CATEGORIES: `/category/cat-list`,
    GET_CATEGORY_BY_ID: (cat_id) => `/category/get-category-by-id/${cat_id}`,
    GET_CATEGORY_DETAIL: (cat_id) => `/category/cat-details/${cat_id}`,
    CREATE_CATEGORY: `/category/cat-add`,
    UPDATE_CATEGORY: `/category/cat-update`,
    DELETE_CATEGORY: (cat_id) => `/category/cat-delete/${cat_id}`,

    // user profile
    UPDATE_ADVANCED_PROFILE: (userId) => `/auth/profile/advance/users/${userId}`,
    GET_ADVANCED_PROFILE: (userId) => `/auth/profile/advance/users/${userId}`,

    SEARCH_BID: `/bid/bids/search`,
    GET_BID_DETAIL: (bid_id) => `/bid/bids/${bid_id}`,
    UPDATE_BID_DETAIL: (bid_id) => `/bid/bids/${bid_id}`,
    NEW_BID_BY_CATEGORY: '/category/stat',
    NEW_BID_BY_COUNTY: '/bid/stat/county',
    OVERALL_PER_DAY: "/bid/stat/overall",
    MOST_TRENDY_BID_CATEGORY: "/bid/stat/trendy/cat",
    MOST_TRENDY_BID_COUNTY: "/bid/stat/trendy/county",
    GET_BID: `/bid/bids/search`,

    GET_ADDRESS_ENTITY: `/address/get-address-entity`,
    GET_BID_SUMMARY_COUNT: (bid_id) => `/bid/bids/${bid_id}/summary-count`,

    GET_USER_LIST:`/auth/users/search-get-user`,
    GET_USER_DETAILS:`/auth/users/`,
    EDIT_USER: `/auth/update/users/`,
    GET_PROJECTS: (userId) => `/auth/client/users/${userId}/projects/search`,
    GET_SINGLE_PROJECT: (userId, projectId) => `/auth/client/users/${userId}/projects/${projectId}`,
    UPDATE_SINGLE_PROJECT: (userId, projectId) => `/auth/client/users/${userId}/projects/${projectId}`,
    CREATE_NEW_PROJECT: (userId) => `/auth/client/users/${userId}/projects`,

    //crawlers
    GET_CRAWLER_LIST:`/bid/crawler-site/get`,
    GET_CRAWLER_DETAILS:(id)=>`/bid/crawler-site/get/${id}`,
    UPDATE_CRAWLER:'/bid/crawler-site/update',
    ADD_CRAWLER: '/bid/crawler-site/add',
    DELETE_CRAWLER: (id) => `/bid/crawler-site/delete/${id}`,
    SEARCH_CROWLER:`/bid/crawler-site/search-crawler`,

    //crawler-log
    SEARCH_CRAWLER_LOG:`/bid/crawler-log/search`,
    UPDATE_CRAWLER_LOG: (id)=>`/bid/crawler-log/${id}`,

    // feedback management
    SEARCH_FEEDBACK:`/subscription/feedback/search`,
    UPDATE_FEEDBACK: (feedback_id) => `/subscription/feedback/${feedback_id}`,
    GET_SINGLE_FEEDBACK: (feedback_id) =>`dev/subscription/feedback/${feedback_id}`,
};

export default ApiUrlServices;
