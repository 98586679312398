import { toast } from "react-toastify";
import { LOCALSTORAGE } from "src/constants/global";
import path from "src/routes/path";
import config from '../config/index'
import { Storage } from "aws-amplify";
import { loginActions } from "src/store/auth/login";

export function setSessionStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
    return true
}

export const truncateWords = (sentence, amount, tail) => {
        // console.log(sentence)
        // alert(sentence)
        const words = sentence.split(' ');

        if (amount >= words.length) {
            return sentence;
        }

        const truncated = words.slice(0, amount);
        return `${truncated.join(' ')}${tail}`;
    };

export const loginUser =(res,history,dispatch,message)=>{ 
    const afterLoginLocation = path.search_bid
    let data = res.data.data
    if (data?.role?.includes('client')) {
        toast.error(`client can't login here`)
        localStorage.clear()
        history.push('/login')
        return
    }
    dispatch(loginActions.addData(res))
    localStorage.setItem(LOCALSTORAGE.userData, JSON.stringify({
        sessionToken: true,
        idToken: data.idToken,
        refreshToken: data.refreshToken,
        accessToken: data.accessToken
    }))
    toast.success(message)
    history.push(afterLoginLocation)
}

export const ErrorHandler = (error, formik) => {
    if (error.response.data.message ==='Email/Phone already exists!'){
        formik.errors.email = "Email already exists!"
    }
    if (error.response.data.code === "USER_NOT_FOUND" || error.response.data.code === "ACCOUNT_DELETED" || error.response.data.code === "GENERIC") {
        formik.errors.email = "No user found with provided email" 
    }
    if (error.response.data.code === "SIGNIN_NOT_AUTHORIZED") {
     formik.errors.email = "Incorrect email or password" 

    }
    if (error.response.data.code === "ACCOUNT_BLOCKED") {
        formik.errors.email = error.response.data.message

    }
    if (error.response.data.code === "USER_NOT_CONFIRMED") {    
        formik.errors.email = "User account not confirmed! please check email for account confirmation link" 

    }
}

let timer;

export const debounce=(func,time)=>{
    
    return ()=>{
        if(timer){
            clearTimeout(timer)
        }
        
        timer = setTimeout(() => {
            func() 
        }, time);
    }

}

export const getDate = (timeStamp,NotMultiple) => {
    if (!timeStamp) return ''
    let multipleBYThousand = NotMultiple ? 1 : 1000
    let date = new Date(Number(timeStamp) * multipleBYThousand)
    let getDay = date.getDate();
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let getMonth = month[date.getMonth()];
    let getYear = date.getFullYear();
    return `${getMonth} ${getDay}, ${getYear}`
}
export const getPureDateObject = (timeStamp,NotMultiple) => {
    if (!timeStamp) return ''
    let multipleBYThousand = NotMultiple ? 1 : 1000
    return new Date(Number(timeStamp) * multipleBYThousand)
    
}

export const countWords = (passedString) => {
    if (passedString === '') return 0
    passedString = passedString.replace(/(^\s*)|(\s*$)/gi, '');
    passedString = passedString.replace(/\s\s+/g, ' ');
    passedString = passedString.replace(/,/g, ' ');
    passedString = passedString.replace(/;/g, ' ');
    passedString = passedString.replace(/\//g, ' ');
    passedString = passedString.replace(/\\/g, ' ');
    passedString = passedString.replace(/{/g, ' ');
    passedString = passedString.replace(/}/g, ' ');
    passedString = passedString.replace(/\n/g, ' ');
    passedString = passedString.replace(/\./g, ' ');
    passedString = passedString.replace(/[\{\}]/g, ' ');
    passedString = passedString.replace(/[\(\)]/g, ' ');
    passedString = passedString.replace(/[[\]]/g, ' ');
    passedString = passedString.replace(/[ ]{2,}/gi, ' ');
    var countWordsBySpaces = passedString.split(' ').length;
    return countWordsBySpaces;
}

export const removeGarbageCharOnFileName = (fileName) => {
    const arr = fileName.split(".");
    const ext = arr.pop();

    let name = arr.join(".");
    const filterData = name.replace(/[^a-zA-Z0-9-_]/g, '');

    const finalStr = `${filterData}.${ext}`;
    console.log(finalStr, 'filename')
    return finalStr;
}

export const sliceString = (str, limit) => {
    if (typeof str !== 'string') return ''
    if (str === '' || str === 'null') return ''
    if (str.length < limit) return str
    return str.slice(0, limit) + '...'
}

export const handleFileUpload = async (file, loader, path, setParcent, cb, setThumb_url) => {
    try {
        let thumb_url = '';
        if (file !== undefined && file.name) {
            loader(true)
            let file_name = removeGarbageCharOnFileName(file.name)
            let pathUrl = `${Date.now()}_${file_name}`;
            console.log('pathurl', pathUrl)
            await Storage.put(pathUrl, file,
                {
                    level: "public",
                    customPrefix: { public: path },
                    progressCallback(progress) {
                        console.log('from progress callback')
                        console.log(`Uploaded: ${Math.round(Number(progress.loaded / progress.total) * 100)}`);
                        setParcent(Math.round(Number(progress.loaded / progress.total) * 100));
                    }
                })
                .then(res => {
                    console.log(res)
                    thumb_url = config.aws.s3_base_url_with_folder_name + path + res.key;
                    console.log(thumb_url, 'thumb_url')
                }).catch(err => {
                    loader(false)
                    toast.error('File upload process failed')
                })
                .finally(() => loader(false))

            setThumb_url(thumb_url)
            setParcent(0)
            cb && cb()
            loader(false)
            return thumb_url

        } else {
            loader(false)
            return false
        }

    } catch (error) {
        toast.error('Something happend wrong')
        loader(false)
    }
}

export const handleUnWantedValue = (value, str) => {
    if (value == false){
        return str
    } else {
        return value
    }
}
