import { bid } from '../../types';

const initialState = {
    bids: [],
};

export default function(state = initialState, action) {
    console.log(state);
    console.log(action);
    switch (action.type) {
        case bid.fetchedBid:
            return {
                ...state,
                bids: [...action.payload.bids],
            };
        default:
            return state;
    }
}
