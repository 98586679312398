const path = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',
  accesslist: '/accesslist',
  search_bid: `/search-bid`,
  manual_search: `/manual-search`,
  manual_details: (id) => `/manual-search/manual-details/${id}`,
  // search_bid: `/search-bid?page=1&status=active`,
  business_verticals: `/business-verticals`,

  // users
  user:`/user`,
  add_user:`/add-user`,
  user_list:`/user-list`,
  single_user:(userId)=>`/user-list/${userId}`,
  add_project: (id) =>`/user-list/${id}/add-project`,
  update_project: (id) =>`/user-list/${id}/update-project`,

  // crawlers
  crawlers:`/crawler-list`,
  log_list:`/log-list`,
  contact_log_list:`/contact-log-list`,

  // email templates
  email_template:`/emailtemplist/`,
  edit_template: (id, datetime) =>`/emailtemplist/${id}/${datetime}`,
  create_template: (create) =>`/emailtemplist/${create}`
};

export default path;
