import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import axios from 'axios';
import '../node_modules/font-awesome/css/font-awesome.min.css';
// import store from './store';
import { store, persistor } from './store/index';
import Amplify from 'aws-amplify';
import { S3_Pool_conf } from './config/s3_config_data';
// import config from './config/base_location.json';
import { API_URL } from './config';
import path from "./routes/path";
import 'react-toastify/dist/ReactToastify.css'
import "react-datetime/css/react-datetime.css";


import { toast } from 'react-toastify';
import { PersistGate } from "redux-persist/integration/react";

toast.configure();

React.icons = icons

axios.interceptors.request.use(request => {
    // Edit request config
    return request;
}, error => {
    return Promise.reject(error);
});


axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401 || error.response.data.code === "USER_NOT_MENTOR") {
        let usersData = JSON.parse(localStorage.getItem("userData"));
        const refreshToken = usersData?.refreshToken;
        return axios.post(`${API_URL.api_base_url}/auth/authentication/refresh_token`,
            JSON.stringify({
                "refresh_token": refreshToken
            }))
            .then(response => {
                usersData["accessToken"] = response.data.data.accessToken
                usersData["idToken"] = response.data.data.idToken

                localStorage.setItem("userData", JSON.stringify(usersData));

                error.response.config.headers['Authorization'] = `Bearer ${response.data.data.idToken}`;
                try {
                    return axios(error.response.config);
                } catch (err) {
                    console.log(err.response);
                }
            })
            .catch(error => {
                localStorage.clear()
                document.cookie.split(";").forEach(function (c) {
                    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                });
                window.location = path.login;
            });
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

Amplify.configure(S3_Pool_conf);


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
