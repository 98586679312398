const baseLocation = require('./base_location');

/*
module.exports = {
    host: 'localhost',
    port: 3001,
    DEFAULT_LANGUAGE: 'en', // for english en and for japanese use ja
    API_URL: baseLocation,
};
*/

module.exports = {
    host: 'localhost',
    port: 3001,
    DEFAULT_LANGUAGE: 'en', // for english en and for japanese use ja
    JSON_API_URL: process.env.REACT_APP_JSON_API_URL,
    API_URL: baseLocation,
    aws: baseLocation.aws,
    payment: baseLocation.payment,
    userRoles: ['Student', 'Instructor'],
    GOOGLE_SEARCH_CONSOLE_SITE_URL: process.env.REACT_APP_GOOGLE_SEARCH_CONSOLE_SITE_URL
};
