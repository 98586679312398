import {
    configureStore,
    combineReducers,
    getDefaultMiddleware,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import testSlice from './testSlice';
import registrationSlice from './auth/registration';
import loginSlice from './auth/login';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import counterReducer from '../services/reducers/counterReducer';
import categoryReducer from '../services/reducers/category/categoryReducer';
import bidReducer from '../services/reducers/bid/bidReducer';
import drawerState from '../services/reducers/sidebar/SideBar';
import userSearchParams from './usersSlice'
import crawlerSearchSlice from './crawlerSearchSlice';
import logSlice from './logSlice';
import contactSlice from './ContactSlice'

const reducers = combineReducers({
    logSlice,
    testSlice,
    contactSlice,
    crawlerSearchSlice,
    registrationSlice,
    loginSlice,
    userSearchParams,
    counterReducer,
    categoryReducer,
    drawerState,
    bidReducer,
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        // immutableCheck: false,
        serializableCheck: false,
    }).concat(logger),
    devTools: process.env.NODE_ENV !== 'production',
});
const persistor = persistStore(store);
export { store, persistor };
