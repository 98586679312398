import React, {Component} from 'react';
import './pageloader.scss'

class CustomPageLoader extends Component {
    render() {
        return (
            <>
                <div className="loading">Loading&#8230;</div>
            </>
        );
    }
}

export default CustomPageLoader;
