import {createSlice} from '@reduxjs/toolkit'

const userSearchInitParams={
    searchParams: {
        searchParams: {
        name: "",
        phone: "",
        email: "",
        role: [],
        emailVerified: [],
        phoneVerified: []
    },
    limit: 10
}
}
const usersSlice=createSlice({
    name: "usersData",
    initialState: userSearchInitParams,
    reducers:{
        setParams(state,actions){
            state.searchParams.searchParams = actions.payload
        }
    }
})
export const usersSliceAction = usersSlice.actions
export default usersSlice.reducer