import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import ApiUrlServices from "../../networks/ApiUrlServices";
import AxiosServices from "../../networks/AxiosService";

// export const fetchLogin = createAsyncThunk(
//     "loginData/fetchLoginLoading",
//     async (data) => {
//         try {
//             // return await axios.post(ApiUrlServices.SIGN_IN, data);
//             return await AxiosServices.post(
//                 ApiUrlServices.SIGN_IN,
//                 data,
//                 false
//             );
//         } catch (e) {
//             console.log("---------error from store---------");
//             console.log(e);
//             console.log("---------error from store---------");
//             return e;
//         }
//     }
// );


const loginInitialState = {
    status: "idle",
    data: {},
    error: {},
};

const loginSlice = createSlice({
    name: "loginData",
    initialState: loginInitialState,
    reducers: {
        addLoading(state){
            state.status ='loading';
            state.data={};
            state.error={}
        },
        addData(state,action){
            console.log('addData');
            state.status = 'idle';
            state.data = action.payload;
            state.error ={}
        },
        addError(state,action){
            state.status ='idle';
            state.data = {};
            state.error= action.payload
        }
    },
    // extraReducers: {
    //     [fetchLogin.pending.type]: (state, action) => {
    //         state = {
    //             status: "loading",
    //             data: action.payload,
    //             error: {},
    //         };
    //     },
    //     [fetchLogin.fulfilled.type]: (state, action) => {
    //         state = {
    //             status: "idle",
    //             data: action.payload,
    //             error: {},
    //         };
    //     },
    //     [fetchLogin.rejected.type]: (state, action) => {
    //         state = {
    //             status: "idle",
    //             data: action.payload,
    //             error: action.payload,
    //         };
    //     },
    // },
});

export const fetchLogin =(data) => {
    return  AxiosServices.post(ApiUrlServices.SIGN_IN, data, false)

}
export const loginActions = loginSlice.actions

export default loginSlice.reducer
