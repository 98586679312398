import { createSlice } from '@reduxjs/toolkit'

const initial = {
    status: [],
    from: '',
    to: '',
}
const contactSlice = createSlice({
    name: "logData",
    initialState: initial,
    reducers: {
        setParams(state, actions) {
            state.status = [actions.payload.status?.[0]]
            state.from = actions.payload.from
            state.to = actions.payload.to
        }
    }
})
export const contactSliceAction = contactSlice.actions
export default contactSlice.reducer
