export const increment_value = 'increment';
export const decrement_value = 'decrement';
export const users_request = 'users_request';
export const get_users_success = 'get_users_success';
export const get_users_failed = 'get_users_failed';

export const category = {
    addCategory: 'add-category',
    fetchedCategory: 'fetched-category',
    updateCategory: 'update-category',
    updateStatus: 'update-status',
    deleteCategory: 'delete-category',
    addSubCategory: 'add-Sub-category',
    updateSubCategory: 'update-Sub-category',

    //Test
    addParentCategory: 'add-parent-category',
    updateParentCategory: 'update-parent-category',
};


export const bid = {
    fetchedBid: 'fetched-bid',
};
