import path from "../routes/path"
const requireLogin = (to, from, next) => {

	const getIsLoggedIn = () => {
		// return true;
		const userData = JSON.parse(localStorage.getItem('userData'));
		return !!userData?.sessionToken;
	}
	if (to.meta.auth) {

		if (getIsLoggedIn()) {
			next();
		}
		else {
			next.redirect(`${path.login}`);
		}
	} 
	else {
		if (getIsLoggedIn()) {
			next.redirect(`${path.search_bid}`);
		}
		else {
			next();
		}
	}
};

export default requireLogin;
