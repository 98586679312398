import { CCard, CCardBody, CCardGroup, CCol, CContainer, CImg, CRow } from '@coreui/react';
import { useFormik } from 'formik';
import { oneOfType } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from "react-validation/build/form";
import Commonbutton from 'src/components/button/Button';
import InputField from 'src/components/inputfield/InputField';
import CustomPageLoader from 'src/components/pageLoader/CustomPageLoader';
import { LOCALSTORAGE } from 'src/constants/global';
import { loginUser } from 'src/helper/CommonFunctions';
import ApiUrlServices from 'src/networks/ApiUrlServices';
import AxiosServices from 'src/networks/AxiosService';
import path from 'src/routes/path';
import { loginActions } from 'src/store/auth/login';


const ChangePassword = () => {
    const [isLoading, setLoading] = useState(false)
    const [pageLoader, setPageLoader] = useState(true)
    const [userData, setUserData] = useState({})
    const dispatch = useDispatch()
    const { search } = useLocation();
    const type = new URLSearchParams(search).get('type');
    const userName = new URLSearchParams(search).get('username');
    const code = new URLSearchParams(search).get('code');
    const history = useHistory()

    const errorToast=(message)=>{
        toast.error(message)
        history.push('/login')
    }
    const initialUserLogin = async () => {
        setPageLoader(true)
        let newUser = {}
        newUser.email = userName;
        newUser.password = code;
        await AxiosServices.post(ApiUrlServices.ADMIN_FIRST_SIGN_IN, newUser)
            .then(res => {
                if (res?.data?.data?.role === 'client') errorToast(`client are not allowed here`)
                if (res.data.code === 'NEW_PASSWORD_REQUIRED') {
                    console.log(res)
                    setUserData(res)
                }
            })
            .catch(err => { errorToast(err.response.data.message) })
            .finally(() => setPageLoader(false))
    }


    const validateLoginForm = (values) => {
        const errors = {};
        console.log(values)
        if (values.password1 !== values.password2) {
            errors.password2 = `password didn't match`
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/i.test(values.password1)) errors.password1 = "Password should contain one uppercase,one lower case, one number and at least 8 characters long";
        if (!values.password1) errors.password1 = "Password is required";
        if (!values.password2) errors.password2 = "Password is required";
        return errors;
     }
    const changePassword = (values) => { 
        setLoading(true)
        let newData ={...userData.data.data}
        newData.newPassword = values.password1
        console.log(newData)
         AxiosServices.post(ApiUrlServices.ADMIN_FIRST_PASS_CHANGE,newData)
            .then(res=>{
                loginUser(res,history,dispatch,'password saved')
            })
             .catch((err) => toast.error(err.response.data.message))
            .finally(()=>setLoading(false))
    }
    const changePassFormik = useFormik({
        initialValues: {
            password1: '',
            password2: '',
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: validateLoginForm,
        onSubmit: changePassword
    })

    useEffect(() => {
         if (!(type && userName && code)) {
             history.push('/login')
             return
         }
        initialUserLogin()
    }, [])
    if(pageLoader){
        return <CustomPageLoader loading={isLoading}/>
    }
    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <h1>Change Password</h1>
                                    <hr />
                                    <Form onSubmit={changePassFormik.handleSubmit}>
                                        <InputField
                                            id="password1"
                                            inputLabel="New Password"
                                            labelLinkText="Try Mobile Number"
                                            placeHolder="New Password"
                                            textType="password"
                                            inputName="password1"
                                            asterisk={true}
                                            whiteSpace={false}
                                            onBlur={changePassFormik.handleBlur}
                                            value={changePassFormik.values.password1}
                                            onchangeCallback={changePassFormik.handleChange}
                                            inputClassName={changePassFormik.touched.password1 && changePassFormik.errors.password1 ? " is-invalid" : ""}
                                            requiredMessage={changePassFormik.touched.password1 && changePassFormik.errors.password1}
                                            requiredMessageLabel={changePassFormik.touched.password1 || changePassFormik.isSubmitting ? changePassFormik.errors.password1 : ""}
                                        />

                                        <InputField
                                            id="password2"
                                            inputLabel="Confirm Password"
                                            labelLinkText="Confirm Password"
                                            placeHolder="Confirm Password"
                                            textType="password"
                                            inputName="password2"
                                            asterisk={true}
                                            whiteSpace={false}
                                            onBlur={changePassFormik.handleBlur}
                                            value={changePassFormik.values.password}
                                            onchangeCallback={changePassFormik.handleChange}
                                            inputClassName={changePassFormik.touched.password2 && changePassFormik.errors.password2 ? " is-invalid" : ""}
                                            requiredMessage={changePassFormik.touched.password2 && changePassFormik.errors.password2}
                                            requiredMessageLabel={changePassFormik.touched.password2 || changePassFormik.isSubmitting ? changePassFormik.errors.password2 : ""}
                                        />

                                        <Commonbutton
                                            type="button"
                                            onclickCallback={changePassFormik.handleSubmit}
                                            disabled={changePassFormik.isSubmitting}
                                            inputClassName="btn btn-primary mt-2"
                                            isLoading={isLoading}
                                            btnText="save"
                                        />
                                    </Form>
                                </CCardBody>
                            </CCard>
                            <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                                <CCardBody className="text-center">
                                    <div>
                                        <CImg
                                            src={'/ct.png'}
                                            width={'250px'}
                                            className="mb-3"
                                            alt="logo"
                                        />
                                        <h2>Admin Panel</h2>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default ChangePassword;