import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import requireLogin from './helper/routerControl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './errorBoundary/ErrorFallback';
import { CSpinner } from '@coreui/react'


import './scss/style.scss';
import ChangePassword from './api/login/ChangePassword';
import CustomPageLoader from './components/pageLoader/CustomPageLoader';

const Loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"/>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./api/login/Login'));
const Page404 = React.lazy(() => import('./api/errors/page404/Page404'));
const Page500 = React.lazy(() => import('./api/errors/page500/Page500'));

class App extends Component {

    render() {
        return (
            <BrowserRouter>
                <React.Suspense fallback={<CustomPageLoader />}>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        {/* <GuardProvider guards={[requireLogin]}  error={<h1>Not allowed</h1>}> */}
                            <Switch>
                                {/* <GuardedRoute exact path="/login" name="Login Page" component={Login} meta={{auth: false}}/> */}
                                <Route exact path="/login" name="Login Page" component={Login}/>
                                <Route exact path="/change-password" name="change password Page" component={ChangePassword}/>
                                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                                <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
                            </Switch>
                        {/* </GuardProvider> */}
                    </ErrorBoundary>
                </React.Suspense>
            </BrowserRouter>
        );
    }
}

export default App;
