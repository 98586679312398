import { createSlice } from '@reduxjs/toolkit'

const crawlerSearchInitParams = {
    searchParams: {
        site_name: "",
        country: [],
        state: [],
        county: [],
        status: [],
        operational: [],
        multiple:[],
        deleted:[]
    }
}
const crawlerSearchSlice = createSlice({
    name: "crawlersSearchData",
    initialState: crawlerSearchInitParams,
    reducers: {
        setParams:(state, actions)=> {
            state.searchParams.site_name = actions.payload.site_name;
            state.searchParams.country= [...actions.payload.country];
            state.searchParams.state= [...actions.payload.state];
            state.searchParams.county= [...actions.payload.county];
            state.searchParams.status= [...actions.payload.status];
            state.searchParams.operational= [...actions.payload.operational];
            state.searchParams.multiple= [...actions.payload.multiple];
            state.searchParams.deleted= [...actions.payload.deleted];
            state.searchParams.start_from_url= actions.payload.start_from_url;

        }
    }
})
export const crawlerSearchSliceAction = crawlerSearchSlice.actions
export default crawlerSearchSlice.reducer