import {category} from '../../types';

const initialState = {
    category: [],
    subCategory: [],
    parent_cat: {},
};

export default function categoryReducer (state = initialState, action) {
    console.log(state);
    console.log(action);
    switch (action.type) {
        case category.addCategory:
            return {
                ...state,
                category: [...state.category, action.payload],
            };
        case category.fetchedCategory:
            return {
                ...state,
                category: [...action.payload.categories],
            };
        case category.deleteCategory:
            return {
                ...state,
                category: [...state.category.filter((cat) => cat.cat_id !== action.payload.cat_id)],
            };
        case category.updateStatus:
            return {
                ...state,
                category: state.category.map(
                    (item, idx) => item.cat_id === action.payload.cat_id ? {
                        ...item,
                        status: action.payload.status,
                    } : item,
                ),
            };
        case category.updateCategory:
            return {
                ...state,
                category: state.category.map(
                    (item, idx) => item.cat_id === action.payload.cat_id ? {...item, ...action.payload.cat} : item,
                ),
            };
        case category.updateParentCategory:
            // console.log('updateParentCategory', action)
            // return state
            return {
                ...state,
                parent_cat: {...state.parent_cat, ...action.payload}
            }

        default:
            return state;
    }
}
