import { Table } from '@material-ui/core'
import { createSlice } from '@reduxjs/toolkit'
import { button } from 'aws-amplify'

const initial = {
    country:[],
    state:[],
    county:[],
    status:[],
    from:'',
    to:'',
}
const logSlice = createSlice({
    name: "logData",
    initialState: initial,
    reducers: {
        setParams(state, actions) {
            state.country = [actions.payload.country?.[0]]
            state.state = [actions.payload.state?.[0]]
            state.county = [...actions.payload.county]
            state.status = [actions.payload.status?.[0]]
            state.from = actions.payload.from
            state.to = actions.payload.to
        }
    }
})
export const logSliceAction = logSlice.actions
export default logSlice.reducer
